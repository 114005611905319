.fullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
  &.video {
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 675px;
    &::after {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
      background: black;
      opacity: .7;
    }
    &.light::after {
      background: white;
      opacity: .9;
    }
    video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100vh;
      min-height: 675px;
      object-fit: cover;
      z-index: -2;
    }
  }
  .hero-text {
    color: $white;
  }
  &.light .hero-text {
    color: $body-color;
  }
}
.pt-80 {
  padding-top: 80px;
}
