.half-image-content {
  position: relative;
  overflow: hidden;
  .image {
    width: 100%;
  }
  .text {
    height: 100%;
    &.pos-right {
      right: 0;
    }
    &.pos-left {
      left: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    .image {
      position: absolute;
      left: 50%;
      width: 50vw;
    }
  }
}

.full-image-content {
  position: relative;
  background-color: $gray-900;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .text.overlay {
    background-color: rgba(0, 0, 0, .6);
    &.light {
      background-color: rgba(255, 255, 255, .7);
    }
  }
}
.parallax-overlay::before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
}